import { fallbackConfig } from '@koala/sdk';
import * as Sentry from '@sentry/nextjs';
import { nanoid } from 'nanoid';
import { type NextPageContext } from 'next';
import { type ErrorProps } from 'next/error';
import Link from 'next/link';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Image } from '@/components/image';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { SESSION_STORAGE_KEYS } from '@/constants/storageKeys';
import { COLORS, LAYOUT, ORDER } from '@/constants/styles';
import { useSelector } from '@/redux';
import KoalaAnalytics, { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { isBrandDomain } from '@/utils/urls';

export const Header = styled.nav`
  align-items: center;
  background: ${fallbackConfig.header.background_color};
  border-bottom: ${LAYOUT.NAV_BOTTOM_BORDER_WEIGHT}px solid ${COLORS.GRAY};
  display: flex;
  height: ${LAYOUT.HEADERHEIGHT}px;
  justify-content: flex-start;
  left: 0;
  padding: 0 ${LAYOUT.GUTTER}px;
  position: relative;
  width: 100%;
  z-index: ${ORDER.HEADER};
  @media (max-width: 767px) {
    height: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
    justify-content: center;
  }
`;

export const Logo = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  left: ${LAYOUT.GUTTER}px;

  a {
    display: block;
  }

  img {
    display: block;
    max-height: ${LAYOUT.HEADERHEIGHT - LAYOUT.GUTTER / 2}px;
    max-width: 200px;
  }

  @media (max-width: 767px) {
    img {
      max-height: ${LAYOUT.MOBILE_HEADERHEIGHT - LAYOUT.GUTTER / 2}px;
    }
  }
`;

const StyledError = styled.div<{ isFixed: boolean }>`
  align-items: center;
  font-family: 'Arial', 'Helvetica', sans-serif;
  display: flex;
  height: calc(100vh - ${LAYOUT.HEADERHEIGHT * 2}px);
  justify-content: center;
  padding: ${LAYOUT.GUTTER}px;
  text-align: center;

  ${({ isFixed }) =>
    isFixed &&
    `
    background: white;
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${ORDER.HEADER};
  `}

  @media (max-width: 767px) {
    height: calc(100vh - ${LAYOUT.MOBILE_HEADERHEIGHT * 2}px);
  }

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }
`;

/**
 * Attempts to retrieve session details from sessionStorage.
 * If none are present, it initializes a new session.
 * @TODO this should be integrated into KoalaAnalytics.
 */
function getSessionDetails() {
  let sessionId = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);
  let sessionStart = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_START);
  if (sessionId && sessionStart) {
    return { sessionId, sessionStart };
  }

  sessionId = nanoid();
  sessionStart = new Date().toISOString();

  window.sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_ID, sessionId);
  window.sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_START, sessionStart);
  return { sessionId, sessionStart };
}

/**
 * This page is loaded by NextJS
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * @see https://nextjs.org/docs/basic-features/data-fetching/overview
 * @see  https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 * @see  https://reactjs.org/docs/error-boundaries.html
 */
export default function ErrorPage({ statusCode }: ErrorProps) {
  const { label, id } = useSelector((state) => state.app.organization.organization);
  let hasBrandDomain = false;
  hasBrandDomain = isBrandDomain();

  /**
   * This Sentry call compensates for an issue in Next.JS prior to 12.2.1.
   * @see https://github.com/vercel/next.js/issues/8592
   * @TODO remove this after upgrading to Next@13
   */
  void Sentry.captureUnderscoreErrorException({ statusCode });

  useEffect(() => {
    const { sessionId, sessionStart } = getSessionDetails();
    try {
      // Ensure the KoalaAnalytics class is always created on app load
      if (!window.KoalaAnalytics) {
        window.KoalaAnalytics = new KoalaAnalytics(sessionId, sessionStart, id, label);
      }

      // Fire KA event
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
        name: ERROR_MESSAGES.APPLICATION_ERROR,
        details: `Status code: ${statusCode}. Href: ${window.location.href}.`,
      });
    } catch (e) {
      Sentry.captureException(e, {
        level: 'error',
        extra: { message: 'Failed to fire Koala Analytics Error Page event' },
      });
    }
  }, [label, statusCode]);

  const getErrorMessage = () => {
    switch (statusCode) {
      case 503:
        return 'An internet connection is needed for online ordering.';
      case 404:
        return `Sorry, we couldn't find anything here.`;
      default:
        return 'Sorry, something went wrong. Try refreshing or go back to the homepage below.';
    }
  };

  return (
    <>
      {fallbackConfig.header.logo && (
        <Header>
          <Logo>
            <Link href="/" legacyBehavior>
              <Image
                alt={label}
                src={fallbackConfig.header.logo}
                height={40}
                objectFit={'contain'}
                width={120}
              />
            </Link>
          </Logo>
        </Header>
      )}
      <StyledError isFixed={!statusCode}>
        <div>
          <h1>Error {statusCode}</h1>
          <h3>{getErrorMessage()}</h3>
          {hasBrandDomain ? (
            // eslint-disable-next-line @next/next/no-html-link-for-pages
            <a href="/stores">Go to homepage</a>
          ) : (
            <Link href="/" passHref>
              Go to homepage
            </Link>
          )}
        </div>
      </StyledError>
    </>
  );
}

ErrorPage.getInitialProps = async (ctx: NextPageContext) => {
  await Sentry.captureUnderscoreErrorException(ctx);
  const { res, err } = ctx;
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
